import Web3 from 'web3';
import CryptoJS from 'crypto-js';
export function numToK(value, max = 9, min = 0) {
    const factor = Math.pow(10, max);
    const truncated = Math.floor(value * factor) / factor;
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });
    return noExponents(formatter.format(truncated));
}
export function handleAmount(value, max = 9, min = 0) {
    let amount = Web3.utils.fromWei(value, 'ether');
    const factor = Math.pow(10, max);
    const truncated = Math.floor(amount * factor) / factor;
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });
    return noExponents(formatter.format(truncated));
}
export function toFixed(value,max=0) {
    let amount = Web3.utils.fromWei(value, 'ether');
    const factor = Math.pow(10, max);
    return Math.floor(amount * factor) / factor;
}
export const noExponents = (exponent) => {
    const data = String(exponent).split(/[eE]/);
    if (data.length === 1) return data[0];
    let z = '', sign = exponent < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;
    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
};

export function int2hex(num, width) {
    /* 转换 */
    var hex = '0123456789abcdef'
    var s = ''
    while (num) {
        s = hex.charAt(num % 16) + s; num = Math.floor(num / 16)
    }
    if (typeof width === 'undefined' || width <= s.length) {
        return '0x' + s
    }
    var delta = width - s.length
    var padding = ''
    while (delta-- > 0) {
        padding += '0'
    }
    return '0x' + padding + s
}

export function idComplex(id, length) {
    return CryptoJS.MD5(id).toString().slice(0, length) + '00' + id;
}

//地址是否相同
export function isSameAddress(address1, address2) {
    return address1 === address2 || String(address1).toLowerCase() === String(address2).toLowerCase();
}
export function handleAddress(address,start = 6, end = 4) {
    return address.slice(0, start) + "..." + address.slice(-end);
}
export function handleTime(time) {
    //转为YYYY-MM-DD HH:mm:ss
    let date = new Date(time * 1000);
    let Y = date.getFullYear();
    let M = date.getMonth() + 1;
    let D = date.getDate();
    let h = date.getHours();
    let i = date.getMinutes();
    let s = date.getSeconds();

    if (M < 10) {
        M = '0' + M
    }
    if (D < 10) {
        D = '0' + D
    }
    if (h < 10) {
        h = '0' + h
    }
    if (i < 10) {
        i = '0' + i
    }
    if (s < 10) {
        s = '0' + s
    }
    return Y + '-' + M + '-' + D + ' ' + h + ':' + i + ':' + s;
}

//把数字转为ether
export function toWei(num) {
    return Web3.utils.toWei(num, 'ether');
}
//把ether转为数字
export function fromWei(num) {
    return Web3.utils.fromWei(num, 'ether');
}