import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loading: null,
		changeNum: 0,
		changeSuperior: 0,
		changeJoin:0,
		tabRefIndex: 0,
		address0: '0x0000000000000000000000000000000000000000',
		SwapView: {
			gbtcBalance: 0, 
			price: 0,
			fund:0,
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
	}
})
