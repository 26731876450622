<template>
	<div class="inviteBox">
		<!-- 我的推荐人 -->
		<div class="title">{{ $t("bind.t1") }}</div>
		<van-field v-model="inviteAddress" placeholder="Address" />
		<!-- 绑定并升级后无法修改，请核对地址是否正确 -->
		<div class="tip">{{ $t("bind.t2") }}</div>
		<div class="btn_box">
			<!-- t22: 取消 -->
			<van-button class="cancel_default_btn" @click="close" :disabled="confirmBindLoading">{{ $t("bind.t3") }}</van-button>
			<!-- t23: 确认 -->
			<van-button class="default_btn" @click="confirmBind" :loading="confirmBindLoading" :loading-text="confirmBindLoadingTXT">{{ $t("bind.t4") }}</van-button>
		</div>
	</div>
</template>

<script>
import { Notify } from "vant-green";
import { ethers } from 'ethers';
export default {
	name: "MarketView",
	props: {},
	data() {
		return {
			inviteAddress: "",
			confirmBindLoading: false,
			confirmBindLoadingTXT: this.$t("bind.t5"),	//"提交中..."
		};
	},
	async mounted() {
		this.inviteAddress =  this.$route.query.superior;
	},
	methods: {
		close(){
			this.$emit("close");
		},
		async confirmBind(){
			//验证地址格式
			if(!ethers.utils.isAddress(this.inviteAddress)){
				Notify({ type: "danger", message: this.$t("bind.t6") });	//"请输入正确的地址"
				return;
			}
			if(this.$utils.isSameAddress(this.inviteAddress, localStorage.getItem("userAccount"))){
				Notify({ type: "danger", message: this.$t("bind.t7") });	//"不能绑定自己"
				return
			}
			//判断上级是否有权限
			var superior 
			if(this.$store.state.config.isCollect){
				superior = await new this.$MyContract().call("collect", "users", [this.inviteAddress]);
			}else{
				superior = await new this.$MyContract().call("mine", "teams", [this.inviteAddress]);
			}
			if(superior.sn<=0 && !this.$utils.isSameAddress(this.$store.state.superiorTop, this.inviteAddress)){
				Notify({ type: "danger", message: this.$t("bind.t8") });	//"您填写的上级不存在"
				return
			}
			this.confirmBindLoading = true;
			this.$store.state.superior = this.inviteAddress;
			this.$store.state.changeSuperior += 1;
			localStorage.setItem(this.$store.state.config.version+"_Superior_"+localStorage.getItem("userAccount"), this.inviteAddress);
			setTimeout(()=>{
				this.confirmBindLoading = false;
				this.$emit("close", true);
			},500)
		}
	},
};
</script>

<style scoped lang="scss">

.inviteBox{
	height: fit-content;
	background-image: linear-gradient(0deg, 
		#262623 0%, 
		#33332f 100%);
	padding: 15px;
	.title{
		text-align: center;
		margin-top: 10px;
		margin-bottom: 30px;
		font-size: 20px;
		font-weight: bold;
		color: #fff;
	}
	.tip{
		font-size: 12px;
		color: #999;
		margin-top: 10px;
	}
	
	.btn_box{
		display: flex;
		gap: 20px;
		margin: 50px 0 10px;
	}
}

</style>
<style>
.connect_wallet .inviteBox .van-field input{
	font-size: 12px !important;
	height: 44px !important;
	background: #1a1a18 !important;
	color: #e0bf8f !important;
	border-radius: 10px !important;
	padding: 0 10px !important;
	text-align: center !important;
}
</style>
